import { render, staticRenderFns } from "./SendToInkasso.vue?vue&type=template&id=6a78e603&scoped=true&"
import script from "./SendToInkasso.vue?vue&type=script&lang=js&"
export * from "./SendToInkasso.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a78e603",
  null
  
)

export default component.exports